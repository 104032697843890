import axios from "./axios.js"

// const token = "1|ku7lIj2vaSsk0iYkGbytMxN5FCu6sdojX6fM2fG0";


export class State {

    static getStates() {
        return axios.get("/states");

    }
    static getStatesOfId(countryId) {

        return axios.post("/states", countryId);

    }
    static getState(stateId) {
        let defaultURL = `/states/${stateId}`;
        return axios.get(defaultURL);
    }
    static getStateData(countryId) {
        let defaultURL = `/statesdata/${countryId}`;
        return axios.get(defaultURL);
    }
    static createState(state) {
        let defaultURL = `/states`;
        // return axios.post(defaultURL,State);
        try {
            return axios.post(defaultURL, state);
        } catch (error) {
            console.log("this is error ", error)
            if (error.response.status === 422) {
                return error.response.data.errors

            }
            // console.log(errors.value.name);
        }
    }
    static updateState(state, stateId) {
        let defaultURL = `/states/${stateId}`;
        return axios.put(defaultURL, state);
    }
    static deleteState(stateId) {
        let defaultURL = `/states/${stateId}`;
        return axios.delete(defaultURL);
    }

}